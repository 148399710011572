<template>
	<div class="content">
		<Header/>
		<img  style="width: 100%;margin: 0 auto;" src="../assets/img/cloudprint/banner.jpg"/>

		<!-- 产品特点 -->
		<div >

			<div>
				<p style="color: #2D6CFF;font-weight: Bold;text-align: center;font-size: 38px;margin-top: 30px;">产品优势</p>
				<p style="color: #20212B;text-align: center;font-weight: Regular;margin-top: 5px;margin-bottom: 10px;">功能强大、操作便捷，一站式服务助力门店降本增效</p>

				<div class="desc">
					<ul >

					<li
						v-for="(item, index) in reason"
						:key="index"
						:style="{
						backgroundColor:item.color
						}"
					>
						<img :src="item.img" alt="" />
						<p>{{ item.title }}</p>
						<p></p>
						<p>{{  item.desc }}</p>
					</li>

			    </ul>
				</div>
			</div>

			<div style="height: 745px;background: #EDF4FC;">
					<div class="cloud-box">
						<p>MASF云打印管理平台</p>
						<p>·支持多平台订单一同打印·</p>
						<p>可以同时打印美团外卖、饿了么外卖、饿了么零售等平台订单，抖店、抖音来客、微盟正接入中。平台订单统一管理，一机 多用，有效提升管</p>
						<img src="../assets/img/cloudprint/multi-platform.png" alt="">
					</div>
			</div>

			<div class="apply-box">
					<div >
						<p>应用简单</p>
						<p>微信小程序端，即开即用，无需下载APP；自主注册，快速登录</p>
						<img src="../assets/img/cloudprint/saoma_03.png" alt="">
					</div>
			</div>

			<div  class="desc-box">
					<div class="item-box">
						<div v-for="(item,index) in traitList" :key="index" class="son-box">
							<p>{{ item.title }}</p>
							<p>{{ item.desc }}</p>
							<img :src="item.img" alt="">
							<div v-if="index==2">
									<span>设置分类排序后打印的小票</span>
									<span>未设置分类排序后打印的小票</span>
							</div>
						</div>
					</div>
			</div>

			<div >
				<div class="cloudIndex-box">
          <div class="son-box">
						<p>云打印开放平台</p>
					  <p>通过云端服务，向POS服务商提供快速实现双色打印能力</p>
					<img src="../assets/img/cloudprint/cloud-platform.png" alt="">

					</div>
					<div class="desc-son-box">
						<div style="width: 12px;height: 167px;background: #2D6CFF;">
						</div>
						<div class="right-box">
							<p>特点:</p>
							<p>标准接口，快速接入。无需复杂对接，只需对接接口即可完成双色文本、双色图片的打印</p>
							<p>小票内容，随改随打。订单数据自由拼接，打印样式随时可变。</p>
							<p>双色图片，快速打印。图片在线转化，定向下发，快速实现图片打印。</p>
						</div>
					</div>
				</div>
			</div>

			<div style="height: 670px;background: #EDF4FC;" class="printDev-box">
				<div class="print-top-box">
					<p>云打印机</p>
					<p>云打印不漏单，双色打印快人一步</p>
					<p>具有80mm、58mm等不同宽度、4g、WiFi等不同联网方式的设备，可根据需求场景购买</p>
				</div>
				<div class="print-bottom-box">
					<div class="print-bottom-left-box">
							<div v-for="(item,index) in cloudPrinterList" :key="index" class="info-left-box">
								<div class="info-img-box">
										<img :src="item.img" alt="">
								</div>
								<div class="info-desc-box">
									<p>{{ item.title }}</p>
									<p>{{ item.desc }}</p>
								</div>

							</div>
					</div>
					<div class="print-bottom-right-box">
            <img src="../assets/img/cloudprint/print_dev.png" alt="">
					</div>
				</div>
			</div>


			<div class="thermal-Paper-box">
				<p class="title">双色热敏纸</p>
				<div class="thermal-top-box">
					<img src="../assets/img/cloudprint/thermal_Paper.png" alt="">
					<div class="thermal-desc-box">
						<div class="thermal-desc-item">
							<p>多种彩色可选择</p>
							<p>红黑、蓝黑等双色热敏纸，双色即时打印，黑色浓郁，传递日常信息； 红/蓝色娇艳，凸显核心数据。无论是营销、还是管理，向用户传达你的心智。</p>

						</div>
						<div class="thermal-desc-item">
							<p>纸张保存时间长</p>
							<p>三防效果好，水洗不掉、油浸不花，在油中浸泡3小时，字迹依然清晰 可见，条码依然可以扫描；平均保存时间高达二十年，达到碳带打印保存标准。</p>
						</div>

					</div>
				</div>
				<div class="thermal-buttom-box">
					<div class="triangle"></div>
					<table style="width: 1013px;" class="table-tin">
						<tr style="height: 65px;">
							<td rowspan="3" style="width: 207px;">
							  <div class="table-title">
									<p>双色</p>
								  <p>热敏纸规格</p>
								</div>
							</td>
							<td style="width: 135px;">
							<p class="td-title">宽度(mm)</p>
							</td>
							<td style="width: 165px;">
								<p>
									<span class="td-info">57</span>
									<span class="td-mini">mm</span>
							  </p>
							</td>
							<td style="width: 165px;"><p>
									<span class="td-info">57</span>
									<span class="td-mini">mm</span>
							  </p></td>
							<td style="width: 165px;"><p>
									<span class="td-info">80</span>
									<span class="td-mini">mm</span>
							  </p></td>
							<td style="width: 165px;"><p>
									<span class="td-info">80</span>
									<span class="td-mini">mm</span>
							  </p></td>
						</tr>
						<tr style="height: 65px;">

							<td style="width: 135px;">
							<p class="td-title">卷径(mm)</p></td>
							<td style="width: 165px;"><p>
									<span class="td-info">40</span>
									<span class="td-mini">mm</span>
							  </p></td>
							<td style="width: 165px;"><p>
									<span class="td-info">50</span>
									<span class="td-mini">mm</span>
							  </p></td>
							<td style="width: 165px;"><p>
									<span class="td-info">40</span>
									<span class="td-mini">mm</span>
							  </p></td>
							<td style="width: 165px;"><p>
									<span class="td-info">50</span>
									<span class="td-mini">mm</span>
							  </p></td>
						</tr>
						<tr style="height: 65px;">

							<td style="width: 135px;"><p class="td-title">
								长度(m)
							</p></td>
							<td style="width: 165px;">

								<p>
									<span class="td-info">20m</span>
									<span class="td-mini">(略有偏差)</span>
							  </p>
							</td>
							<td style="width: 165px;"><p>
									<span class="td-info">25m</span>
									<span class="td-mini">(略有偏差)</span>
							  </p></td>
							<td style="width: 165px;"><p>
									<span class="td-info">20m</span>
									<span class="td-mini">(略有偏差)</span>
							  </p></td>
							<td style="width: 165px;"><p>
									<span class="td-info">25m</span>
									<span class="td-mini">(略有偏差)</span>
							  </p></td>
						</tr>
					</table>

				</div>
			</div>

			<div style="height: 465px;background: #EDF4FC;" class="contact-box">
					<div class="contact-item-top">
						<p>联系我们</p>
						<p>双色打印商务服务热线</p>
					</div>
					<div class="contact-item-main">
						<p>CMO(问西航)</p>
						<p>双色板块商务负责人，欢迎您来电咨询</p>
						<p style="width: 30px;height: 5px;background: #20212B;border-radius: 3px;"></p>
					</div>
					<div class="contact-item-bottom">
						<div class="contact-left-item">
							<div class="contact-left-son">
								<img src="../assets/img/cloudprint/ph.png" alt="">
								<p>156-0929-2022</p>
							</div>
							<div class="contact-left-son">
								<img style="margin-top: 5px;" src="../assets/img/cloudprint/em.png" alt="">
								<p>wenxihang@kymasf.com</p>
							</div>
						</div>
						<div class="contact-right-item">
							<div class="contact-right-son">
								<p>扫一扫</p>
								<p>添加微信好友</p>
							</div>
							<div>
								<img src="../assets/img/cloudprint/qr.png" alt="">
							</div>

						</div>
					</div>
			</div>
		</div>
	</div>
</template>

<script>
const Header = ()=>import('@c/header.vue')

export default {
	components:{
		Header,
	},
	computed:{
		bannerUrl() {
		  return 'https://oss-www.kymasf.com/img/material/banner/'
		},
	},
	data() {
		return {
			reason: [
        {
          img: require("../assets/img/cloudprint/a1.png"),
          title: "多用途双色小票",
          color: "#2C608A",
          desc: "独具一格的双色小票，彩色突出重点。点餐份数、客户备注不错看、不漏看，提升配餐效率，降低错配成本。支持店铺logo、营销图片双色打印，加深品牌印象、便于私域引流。",
        },
        {
					img:require("../assets/img/cloudprint/a2.png"),
          title: "多平台接单",
          color: "#2D6CFF",
          desc: "常见平台统一接入，订单统一管理，一台设备即可打印多平台的订单。完成授权后即可自动接单、打单，方便省力，无需频繁关注。",
        },
        {
					img: require("../assets/img/cloudprint/a3.png"),
          title: "多方案防漏单",
          color: "#2C608A",
          desc: "来单语音播报，提醒店内人员；断电、断网、缺纸恢复后云端自动重打；WiFi/4G/USB/蓝牙多通道可选，信号稳定，适配各种打印场景。",
        },
				{
					img: require("../assets/img/cloudprint/a4.png"),
          title: "特色功能助力运营",
          color: "#2D6CFF",
          desc: "支持商品排序，自定义菜品打印顺序，提升麻辣烫、烧烤等细分行业拣货效率；支持分单打印，前台、后厨可同时出单，降低沟通成本，提高出餐效率。",
        },
      ],
			traitList:[
				{
					img: require("../assets/img/cloudprint/1.png"),
          title: '凸显关键信息',
					desc:'设置小票关键信息双色显示。'
				},
				{
					img: require("../assets/img/cloudprint/2.png"),
          title: '营销图片打印',
					desc:'自定义上传品牌logo、营销图片并打印。'
				},
				{
					img: require("../assets/img/cloudprint/3.png"),
          title: '商品排序打印',
					desc:'设置菜品打印顺序，多菜品井然有序。'
				},
				{
					img: require("../assets/img/cloudprint/4.png"),
          title: '模板免费使用',
					desc:'海量热点模板不断更新，一键替换使用。'
				}
			],

			cloudPrinterList:[
				{
					img:require("../assets/img/cloudprint/01.png"),
					title: '快速配网',
					desc: '支持多种网络连接方式，只需两步即可成功联网；支持网络切换，方便快捷。',
			  },
				{
					img:require("../assets/img/cloudprint/02.png"),
					title: '云端打印',
					desc: '配网成功后即可开始打印，无需手机电脑。',
			  },
				{
					img:require("../assets/img/cloudprint/03.png"),
					title: '打印稳定',
					desc: '接单即打，快速出单；离线、缺纸等异常排除后，未完成的小票自动重打。',
			  },
				{
					img:require("../assets/img/cloudprint/04.png"),
					title: '简单易用',
					desc: '真人语音提醒，快速辨别小票种类；远程音量调节，掌握设置状态；设备 故障提醒，故障信息实时播报，及时排障。',
			  }
		  ]
		}
	},
	methods: {

		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if (rowIndex % 3 === 0) {
            return {
              rowspan: 3,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
	}
}
</script>

<style lang="less" scoped>
 .content{
	width: 100%;
	height: 100%;
  // overflow-x: hidden;
	 .desc{
		margin:30px auto;
		width: 1200px;

		 ul{
			display: flex;
      flex-wrap: wrap;
			justify-content: space-between;
			li{
				padding: 25px 23px 16px 22px;
			  width: 270px;
				// height: 324px;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				box-sizing: border-box;
				img{
					width: 92px;
					height: 92px;
				}
				p{
					&:nth-of-type(1){
						margin-top: 10px;
						font-weight: Bold;
						font-size: 26px;
						color: #FFFFFF;
					}
					&:nth-of-type(2){
						margin-top: 10px;
						margin-bottom: 10px;
						width: 32px;
						height: 4px;
						background: #FFFFFF;
						border-radius: 2px;
						box-sizing: border-box;
					}
					&:nth-of-type(3){
						text-align: center;
						// width: 225px;
						// height: 116px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						font-size: 16px;
						color: #FFFFFF;
						line-height: 20px;

					}

				}
			}
		 }
	 }

	 .cloud-box{
		position: relative;
		margin: 0 auto;
		width: 1200px;
		height: 745px;
		background-image: url('../assets/img/cloudprint/bg_01.png');
		background-size: contain;
		background-position: bottom left;
		background-repeat:no-repeat;
		p{
			&:nth-of-type(1){
				padding-top: 46px;
			  text-align: center;
				font-family: Source Han Sans CN;
				font-weight: bold;
				font-size: 38px;
				color: #2D6CFF;
			}
			&:nth-of-type(2){
				padding-top: 46px;
				text-align: center;
				font-family: Source Han Sans CN;
				font-weight: bold;
				font-size: 26px;
				color: #20212B;
			}
			&:nth-of-type(3){
				margin: 0 auto;
				padding-top: 19px;
				padding-bottom: 43px;
				width: 868px;
				height: 35px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				font-size: 16px;
				color: #20212B;
				text-align: center;
			}
		}
		img{
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
		}
	 }

	 .apply-box{
		width: 100%;
		height: 620px;
		background-image: url(../assets/img/cloudprint/bg_02.png);
		background-size: cover;
		
		div{
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 40px;
			p{
				&:nth-of-type(1){
					text-align: center;
					font-family: Source Han Sans CN;
					font-weight: bold;
					font-size: 26px;
					color: #FFFFFF;
				}
				&:nth-of-type(2){
					margin-top: 10px;
					margin-bottom:40px ;
					text-align: center;
					font-family: Source Han Sans CN;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
				}
			}
		}
	 }

	 .desc-box{
		height: 1193px;
		background: #EDF4FC;
		.item-box{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-content:space-between;
			padding-top: 73px;
			padding-bottom: 91px;
			width: 1200px;
			height: 1193px;
			margin: 0 auto;
			box-sizing: border-box;

			.son-box{
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: column;
				width: 584px;
				height: 500px;
				background: #FFFFFF;
				border-radius: 10px;
				position: relative;
		
				div{
					position: absolute;
					left: 10px;
					bottom: 35px;
					display: flex;
					justify-content: space-evenly;
					width: 100%;
					span{
						// display: inline-block;
						&:nth-of-type(1){
							font-family: Source Han Sans CN;
							font-weight: bold;
							font-size: 14px;
							color: #2D6CFF;
							line-height: 16px;
						}
						&:nth-of-type(2){
							font-family: Source Han Sans CN;
							font-weight: bold;
							font-size: 14px;
							color: #20212B;
							line-height: 16px;
						}
					}
				}

				p{
					&:nth-of-type(1){
						position: absolute;
						top: 22px;
						left: 27px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						font-size: 26px;
						color: #2D6CFF;
						line-height: 22px;
					}
					&:nth-of-type(2){
						position: absolute;
						top: 57px;
						left: 23px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						font-size: 18px;
						color: #20212B;
						line-height: 22px;
					}
			  }
				img{
					position: absolute;
					top: 90px;
					// left: 68px;
				}
			}
		}
	 }

	 .cloudIndex-box{
		display: flex;
		align-items: center;
		flex-direction: column;
		height: 743px;
		background: #FFFFFF;
		.son-box{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			p{
				&:nth-of-type(1){
					margin-top: 48px;
					margin-bottom: 20px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					font-size: 38px;
					color: #2D6CFF;
				}
				&:nth-of-type(2){
					margin-bottom: 45px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					font-size: 16px;
					color: #20212B;
				}
		   }
			img{
				margin-bottom: 40px;
			}
		}

		.desc-son-box{
			display: flex;
			width: 1200px;
			height: 167px;
			.right-box{
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				padding-left: 23px;
				box-sizing: border-box;
				box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
				p{
					&:nth-of-type(1){

						font-family: Source Han Sans CN;
						font-weight: bold;
						font-size: 26px;
						color: #2D6CFF;
					}
					&:nth-of-type(2){

						font-family: Source Han Sans CN;
						font-weight: 400;
						font-size: 20px;
						color: #20212B;
					}

					&:nth-of-type(3){

						font-family: Source Han Sans CN;
						font-weight: bold;
						font-size: 18px;
						color: #2D6CFF;
					}

					&:nth-of-type(4){

						font-family: Source Han Sans CN;
						font-weight: bold;
						font-size: 18px;
						color: #2D6CFF;
					}


				}
			}
		}

	 }

	 .printDev-box{
		.print-top-box{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 60px;
			p{
				&:nth-of-type(1){
					margin-top: 45px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					font-size: 38px;
					color: #2D6CFF;
				}
				&:nth-of-type(2){
					margin-top: 10px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					font-size: 16px;
					color: #20212B;
				}
				&:nth-of-type(3){
					margin-top: 10px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					font-size: 16px;
					color: #20212B;
				}
			}
		}
		.print-bottom-box{
			display: flex;
		 justify-content: space-between;
			margin: 0 auto;
			width: 1200px;
			.print-bottom-left-box{
				display: flex;
				justify-content: space-around;
				flex-direction: column;
			.info-left-box{
				display: flex;
				.info-img-box{
					display: flex;
					align-items: center;
				}
				.info-desc-box{
					margin-left: 28px;
					p{
						&:nth-of-type(1){

						font-family: Source Han Sans CN;
						font-weight: bold;
						font-size: 26px;
						color: #20212B;
						}
						&:nth-of-type(2){

						  width: 550px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							font-size: 16px;
							color: #20212B;
						}

					}
				}
			}
		 }
		}
	 }

	 .thermal-Paper-box{
		padding-top: 50px;
		height: 752px;
		background: #fff;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		.title{
			font-family: Source Han Sans CN;
			font-weight: bold;
			font-size: 38px;
			color: #2D6CFF;
		}
		.thermal-top-box{
			width: 1200px;
			display: flex;
			.thermal-desc-box{
				margin-left: 30px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				.thermal-desc-item{
					p{
						&:nth-of-type(1){
							font-family: Source Han Sans CN;
							font-weight: bold;
							font-size: 26px;
							color: #20212B;
						}
						&:nth-of-type(2){
							  margin-top: 10px;
							  font-family: Source Han Sans CN;
								font-weight: 400;
								font-size: 18px;
								color: #20212B;
						}

					}
				}
			}
		}
		.thermal-buttom-box{
			position: relative;
			.triangle{
				position: absolute;
				left: 46px;
				top: -15px;
				width: 33px;
				height: 33px;
				transform: rotate(45deg);
				background: #B9D6FF;

			}
			.table-tin{

				border-collapse: separate;
				border-spacing: 0;

				border-radius: 12px;

				border: 4px solid #B9D6FF;

				td{
					text-align: center;
					border: 1px solid #B9D6FF;
					.table-title{
						width: 100%;
						height: 195px;
						background: #B9D6FF;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						p{
							font-family: Source Han Sans SC;
							font-weight: bold;
							font-size: 34px;
							color: #FFFFFF;
						}

					}

					.td-title{
						font-family: Source Han Sans SC;
						font-weight: 400;
						font-size: 18px;
						color: #20212B;
					}
					.td-info{
						font-family: Source Han Sans SC;
						font-weight: bold;
						font-size: 26px;
						color: #20212B;
					}
					.td-mini{
						font-family: Source Han Sans SC;
						font-weight: bold;
						font-size: 13px;
						color: #20212B;
					}
				}

			}
		}



	 }

   .contact-box{
		padding-top: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
			.contact-item-top{
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 1046px;
				p{
         &:nth-of-type(1){
					font-family: Source Han Sans CN;
					font-weight: bold;
					font-size: 38px;
					color: #2D6CFF;
         }
				 &:nth-of-type(2){
					margin-top: 3px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					font-size: 16px;
					color: #20212B;
				 }


				}
			}
			.contact-item-main{
				margin-top: 45px;
				width: 1046px;
				p{
					&:nth-of-type(1){
							font-family: Source Han Sans CN;
					    font-weight: bold;
					    font-size: 22px;
					    color: #20212B;
					 }
					 &:nth-of-type(2){
						margin-top: 5px;
						 font-family: Source Han Sans CN;
							font-weight: 400;
							font-size: 16px;
							color: #20212B;
					 }
					 &:nth-of-type(3){
							margin-top: 10px;
					 }


				}
			}
			.contact-item-bottom{
				padding: 10px;
				margin-top: 10px;
				width: 1046px;
				width: 1046px;
				height: 163px;
				background: #FFFFFF;
				border-radius: 6px;
				border: 1px solid #B9D6FF;
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;

				.contact-left-item{
					padding-left: 10px;
					width: 650px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					border-right: 1px dashed #B9D6FF;
					.contact-left-son{
						display: flex;
						align-items: center;

						p{

							&:nth-of-type(1){
								margin-left: 20px;
								font-family: Source Han Sans CN;
								font-weight: bold;
								font-size: 38px;
								color: #2D6CFF;
							}
						}
					}
				}
				.contact-right-item{

					display: flex;
					align-items: center;
					justify-content: space-around;

				.contact-right-son{

					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;

					p{
						margin-right:50px ;
						font-family: Source Han Sans CN;
						font-weight: bold;
						font-size: 18px;
						color: #20212B;
					}
				}
				}
			}
   }

 }
</style>
